import { SidebarType } from "../../common/types/sidebar.types";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { useState } from "react";
import { SidebarNavigation } from "../../components/sidebar-navigation/sidebar-navigation";

export type SidebarGroupsProps = {
  group: {
    group_name: string;
    group_icon: string;
    isActive: boolean;
    links: SidebarType[];
  };
  collapsed?: boolean;
};

export const SidebarGroups = ({ group, collapsed }: SidebarGroupsProps) => {
  const [isMenuShown, setIsMenuShown] = useState<boolean>(group.isActive);

  return (
    <div
      className={`${
        isMenuShown ? "bg-gray-200 bg-opacity-20" : ""
      } text-white py-4 pl-8 pr-4 cursor-pointer`}
      onClick={() => setIsMenuShown(!isMenuShown)}
    >
      <div className="flex items-center mb-2">
        {group.group_icon && (
          <img
            src={group.group_icon}
            className="text-white text-sm mr-2 w-6 h-6"
            alt={group.group_name}
          />
        )}
        <p className="text-sm font-semibold">{group.group_name}</p>
        <button className="ml-auto">
          <FiChevronDown
            className={`${
              group.isActive || isMenuShown ? "rotate-180" : ""
            } transform duration-150`}
          />
        </button>
      </div>
      <div
        className={`${
          isMenuShown ? "h-auto" : "h-0 overflow-hidden"
        } pl-8 transition-all duration-150`}
      >
        {group.links.map((link, i) => {
          return (
            <SidebarNavigation key={i} collapsed={collapsed} data={link} />
          );
        })}
      </div>
    </div>
  );
};
