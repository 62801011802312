import { useCallback, useEffect } from "react";
import useUserStore from "../../../core/stores/user.store";
import { useHistory } from "react-router-dom";
import { AppRoute } from "../../../common/routes/app.route.util";
import { getUser } from "../../../core/services/user.service";
import { Spinner } from "../../../components/spinner/spinner";
import { UserRoleType, UserType } from "../../../core/types/user.type";
import { logout, userAuthCheck } from "../../../common/helpers/auth.helper";

export interface AuthWrapperProps {
  children: JSX.Element;
}

export default function AuthWrapper({ children }: AuthWrapperProps) {
  const {
    user,
    setUserData,
  }: { user: UserType; setUserData: (user: UserType) => void } = useUserStore();
  const token = sessionStorage.getItem("token");
  const history = useHistory();

  const fetchUserData = useCallback(async () => {
    try {
      const res = await getUser();
      setUserData(res.data);
    } catch (error) {
      userAuthCheck(error);
    }
  }, [setUserData]);

  useEffect(() => {
    if (token) {
      if (!user.id) {
        fetchUserData();
      }
    } else {
      history.push(AppRoute.users.login);
    }
  }, [fetchUserData, history, token, user.id]);

  if (!user.analytics_id) {
    return (
      <div className="text-black flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (
    user.id &&
    user.roles.find((role: UserRoleType) => role.key === "SUPERADMIN")
  ) {
    return <div>{children}</div>;
  } else {
    logout();
  }

  return <></>;
}
