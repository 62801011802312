import { Link } from "react-router-dom";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";

import { SidebarType } from "../../common/types/sidebar.types";

export interface SidebarNavigationProps {
  collapsed?: boolean;
  data: SidebarType;
}

const content = (data: SidebarType, collapsed: boolean) => (
  <>
    <div
      className={`flex items-center w-full ${
        !data.isActive ? "opacity-50" : ""
      }`}
    >
      {data.icon && (
        <img
          src={data.icon}
          className="text-white text-sm h-6 w-6"
          alt={data.title}
        />
      )}
      <span
        className={`${
          collapsed ? "w-0 hidden" : "w-max"
        } text-white text-xs ml-4 overflow-hidden transition-all duration-500`}
      >
        {data.title}
      </span>
      {data.isActive && <FiArrowRight className="ml-auto" />}
    </div>
  </>
);

export const SidebarNavigation = ({
  data,
  collapsed = false,
}: SidebarNavigationProps) => {
  return (
    <button
      className={`${
        collapsed && "justify-center"
      } group flex items-center w-full h-10 p-2 rounded-lg transition-all duration-500`}
      disabled={data.isActive && !collapsed}
    >
      <Link
        className={`w-full flex items-center ${
          collapsed ? "justify-center" : ""
        }`}
        to={data.url}
      >
        {content(data, collapsed)}
      </Link>
    </button>
  );
};
