import { useLocation } from "react-router-dom";

import { getGroupedAdminSidebarMenu } from "./sidebar.helper";

import logo from "../../assets/icons/logo-white.svg";
import { SidebarGroups } from "../../components/sidebar-groups/sidebar-groups";

export type SidebarProps = {
  collapsed?: boolean;
};

export const Sidebar = ({ collapsed }: SidebarProps) => {
  const location = useLocation();

  return (
    <div
      className={`${
        collapsed
          ? "sm:w-32 w-0 overflow-hidden py-4 px-1"
          : "sm:w-66 w-screen"
      } sm:h-screen h-sidebar-mobile sm:mt-0 mt-20 bg-body text-center fixed overflow-y-auto transition-all duration-500 z-20`}
    >
      <div className="bg-black w-full h-full py-10 overflow-scroll">
        <div className="w-full flex items-center justify-center mb-6">
          <div className="w-full h-16 flex items-center gap-4 justify-center pb-2">
            <img src={logo} alt="oro" className="w-auto h-10" />
            {!collapsed && <p className="text-xl text-white font-bold">Dashboard</p>}
          </div>
        </div>
        {getGroupedAdminSidebarMenu(location.pathname).map((item, index) => {
          return (
            <SidebarGroups group={item} collapsed={collapsed} key={index} />
          );
        })}
      </div>
    </div>
  );
};
