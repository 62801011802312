import { Spinner } from "./components/spinner/spinner";
import React, { ComponentType } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import "tailwindcss/tailwind.css";
import { AppRoute } from "./common/routes/app.route.util";
import AuthWrapper from "./components/wrappers/auth-wrapper/auth-wrapper";
import LayoutWrapper from "./components/wrappers/layout-wrapper/layout-wrapper";

const withSuspense = (WrappedComponent: React.ComponentType) => (
  <React.Suspense
    fallback={
      <div className="text-black flex items-center justify-center">
        <Spinner />
      </div>
    }
  >
    <WrappedComponent />
  </React.Suspense>
);

const withAuth = (WrappedComponent: JSX.Element) => (
  <AuthWrapper>{WrappedComponent}</AuthWrapper>
);

const withLayout = (WrappedComponent: JSX.Element) => (
  <LayoutWrapper>{WrappedComponent}</LayoutWrapper>
);

const layoutAuthSuspense = (path: ComponentType) => (
  withLayout(withAuth(withSuspense(path)))
);

function AppRouting() {
  const adminRoutes: JSX.Element[] = [
    <Route
      exact
      key="login"
      path={AppRoute.users.login}
      component={() => withSuspense(React.lazy(() => import("./pages/auth/login")))}
    />,

    < Route
      exact
      key="users"
      path={AppRoute.users.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/users/users-list")))}
    />,
    < Route
      exact
      key="adminBrandsList"
      path={AppRoute.brands.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/brands/brands-list")))}
    />,
    < Route
      exact
      key="adminBrandCreate"
      path={AppRoute.brands.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/brands/create-brand")))}
    />,
    < Route
      exact
      key="adminBrandEdit"
      path={AppRoute.brands.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/brands/edit-brand")))}
    />,
    < Route
      exact
      key="adminCategoriesList"
      path={AppRoute.categories.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/category/categories-list")))}
    />,
    < Route
      exact
      key="adminCategoryCreate"
      path={AppRoute.categories.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/category/create-category")))}
    />,
    < Route
      exact
      key="adminCategoryEdit"
      path={AppRoute.categories.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/category/categories-list")))}
    />,
    < Route
      exact
      key="adminProductsList"
      path={AppRoute.products.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/product/products-list")))}
    />,
    < Route
      exact
      key="adminProductCreate"
      path={AppRoute.products.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/product/create-product")))}
    />,
    < Route
      exact
      key="adminProductEdit"
      path={AppRoute.products.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/product/edit-product")))}
    />,
    < Route
      exact
      key="adminVariantsList"
      path={AppRoute.variants.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/variant/variants-list")))}
    />,
    < Route
      exact
      key="adminVariantCreate"
      path={AppRoute.variants.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/variant/create-variant")))}
    />,
    < Route
      exact
      key="adminVariantEdit"
      path={AppRoute.variants.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/variant/edit-variant")))}
    />,
    < Route
      exact
      key="adminInvitationsList"
      path={AppRoute.general.invitations}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/invitations")))}
    />,
    < Route
      exact
      key="adminContactUsList"
      path={AppRoute.general.contactUs}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/contact_us/contact-us-list")))}
    />,
    < Route
      exact
      key="adminCurrencysList"
      path={AppRoute.currencies.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/currency/currencies-list")))}
    />,
    < Route
      exact
      key="adminCurrencyCreate"
      path={AppRoute.currencies.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/currency/create-currency")))}
    />,
    < Route
      exact
      key="adminCurrencyEdit"
      path={AppRoute.currencies.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/currency/edit-currency")))}
    />,
    < Route
      exact
      key="adminAttributesList"
      path={AppRoute.attributes.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/attribute/attributes-list")))}
    />,
    < Route
      exact
      key="adminAttributeCreate"
      path={AppRoute.attributes.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/attribute/create-attribute")))}
    />,
    < Route
      exact
      key="adminAttributeEdit"
      path={AppRoute.attributes.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/attribute/edit-attributes")))}
    />,
    < Route
      exact
      key="adminAvailableAttributesList"
      path={AppRoute.availableAttributes.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/available-attributes/available-attributes-list")))}
    />,
    < Route
      exact
      key="adminAvailableAttributeCreate"
      path={AppRoute.availableAttributes.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/available-attributes/create-available-attributes")))}
    />,
    < Route
      exact
      key="adminAvailableAttributeEdit"
      path={AppRoute.availableAttributes.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/available-attributes/edit-available-attributes")))}
    />,
    < Route
      exact
      key="adminOrganizationsList"
      path={AppRoute.organizations.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/organizations/organizations-list")))}
    />,
    < Route
      exact
      key="adminOrganizationsCreate"
      path={AppRoute.organizations.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/organizations/create-organization")))}
    />,
    < Route
      exact
      key="adminOrganizationsEdit"
      path={AppRoute.organizations.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/organizations/edit-organization")))}
    />,
    < Route
      exact
      key="adminOrganizationDetails"
      path={AppRoute.organizations.details}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/organizations/organization-details")))}
    />,
    < Route
      exact
      key="adminOffersList"
      path={AppRoute.offers.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/offers/offers-list")))}
    />,
    < Route
      exact
      key="adminOffersCreate"
      path={AppRoute.offers.create}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/offers/create-offer")))}
    />,
    < Route
      exact
      key="adminOfferEdit"
      path={AppRoute.offers.edit}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/offers/edit-offer")))}
    />,
    < Route
      exact
      key="adminOfferDetails"
      path={AppRoute.offers.details}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/offers/offer-details")))}
    />,
    < Route
      exact
      key="adminCartsList"
      path={AppRoute.carts.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/carts/carts-list")))}
    />,
    < Route
      exact
      key="adminDevicesList"
      path={AppRoute.general.devices}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/devices/devices-list")))}
    />,
    < Route
      exact
      key="adminGeozonesList"
      path={AppRoute.general.geozones}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/geozones/geozones-list")))}
    />,
  ];

  const dashboard: JSX.Element[] = [
    < Route
      exact
      key="dashboard-statistics"
      path={AppRoute.dashboard.statistics}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/dashboard/statistics")))}
    />,
    < Route
      exact
      key="dashboard-orders-report"
      path={AppRoute.dashboard.ordersReport}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/dashboard/orders-report")))}
    />,
  ]

  const ordersRoutes: JSX.Element[] = [
    <Route
      exact
      key="orders"
      path={AppRoute.orders.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/order/order-list")))}
    />,
    <Route
      exact
      key="orderDetails"
      path={AppRoute.orders.details}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/order/order-details")))}
    />
  ]

  const vehicleRoutes: JSX.Element[] = [
    < Route
      exact
      key="adminVehiclesList"
      path={AppRoute.vehicles.list}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/vehicles/vehicles-list")))}
    />,
    < Route
      exact
      key="adminModelsMakesList"
      path={AppRoute.vehicles.modelsMakes}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/models-makes/models-makes-list")))}
    />,
    < Route
      exact
      key="adminCreateMake"
      path={AppRoute.vehicles.createMake}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/models-makes/create-make")))}
    />,
    < Route
      exact
      key="adminCreateModel"
      path={AppRoute.vehicles.createModel}
      component={() => layoutAuthSuspense(React.lazy(() => import("./pages/models-makes/create-model")))}
    />,
  ]


  const commonRoutes = [
    // <Route
    //   exact
    //   key="404"
    //   path="/404"
    //   component={() => withSuspense(Custom404)}
    // />,
    // <Route
    //   exact
    //   key="401"
    //   path="/401"
    //   component={() => withSuspense(Custom401)}
    // />,
    <Redirect
      path="*"
      to={AppRoute.orders.list}
      exact
      key="redirect"
    />,
  ];

  const finalRoutes = [...dashboard, ...ordersRoutes, ...adminRoutes, ...vehicleRoutes, ...commonRoutes];

  return (
    <React.Suspense
      fallback={
        <div className="text-black flex items-center justify-center">
          <Spinner />
        </div>
      }
    >
      <Switch>{finalRoutes}</Switch>
    </React.Suspense>
  );
}

export default AppRouting;
