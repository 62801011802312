import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import AppRouting from "./app-routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // i18n.changeLanguage(localStorage.getItem("language") || "en");
    i18n.changeLanguage("en");
  }, [i18n]);

  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          theme="colored"
        />
        <AppRouting />
      </BrowserRouter>
    </div>
  );
}

export default App;
