import { useState } from "react";
import { Sidebar } from "../../sidebar/sidebar";
import { FiAlignJustify } from "@react-icons/all-files/fi/FiAlignJustify";
import { logout } from "../../../common/helpers/auth.helper";

export interface LayoutWrapperProps {
  children: JSX.Element;
}

export default function LayoutWrapper({ children }: LayoutWrapperProps) {
  const [isSideBarCollapsed, seIsSideBarCollapsed] = useState(false);

  return (
    <div className="flex min-h-screen bg-body relative">
      <Sidebar collapsed={isSideBarCollapsed} />
      <div
        className={`${
          isSideBarCollapsed ? "sm:pl-32" : "sm:pl-66"
        } transition-all duration-500 pb-8 w-full min-h-screen`}
      >
        <div className="m-4 mb-10 bg-white rounded-lg shadow-md flex items-center justify-between px-4 py-2">
          <div className="bg-transparent p-1 cursor-pointer">
            <button onClick={() => seIsSideBarCollapsed(!isSideBarCollapsed)}>
              <FiAlignJustify className="text-blue-700 text-2xl hover:opacity-75" />
            </button>
          </div>
          <div className="bg-transparent p-1 cursor-pointer">
            <button
              onClick={() => logout()}
              className="font-semibold text-gray-500 text-xs cursor-pointer hover:opacity-75"
            >
              Logout
            </button>
          </div>
        </div>
        <div className="container max-w-5xl w-full mx-auto">{children}</div>
      </div>
    </div>
  );
}
