class OrderRoutes {
  list = "/orders";
  details = "/orders/:order_id";

  detailsWith(order_id: string): string {
    return this.details.replace(":order_id", order_id);
  }
}

class CartsRoutes {
  list = `/carts`;
}

class UsersRoutes {
  login = "/login";
  otp = "/login/otp";
  list = "/users";
}

class BrandsRoutes {
  list = "/brands";
  create = "/brands/create";
  edit = "/brands/:brand_id/edit";

  editWith(brand_id: string): string {
    return this.edit.replace(":brand_id", brand_id);
  }
}

class CategoriesRoutes {
  list = "/categories";
  create = "/categories/create";
  edit = "/categories/:category_id/edit";

  editWith(category_id: string): string {
    return this.edit.replace(":category_id", category_id);
  }
}

class ProductsRoutes {
  list = "/products";
  create = "/products/create";
  edit = "/products/:product_id/edit";

  editWith(product_id: string): string {
    return this.edit.replace(":product_id", product_id);
  }
}

class CurrenciesRoutes {
  list = "/currencies";
  create = "/currencies/create";
  edit = "/currencies/:currency_id/edit";

  editWith(currency_id: string): string {
    return this.edit.replace(":currency_id", currency_id);
  }
}

class AttributesRoutes {
  list = "/attributes";
  create = "/attributes/create";
  edit = "/attributes/:attribute_id/edit";

  editWith(attribute_id: string): string {
    return this.edit.replace(":attribute_id", attribute_id);
  }
}

class AvailableAttributesRoutes {
  list = "/available-attributes";
  create = "/available-attributes/create";
  edit = "/available-attributes/:available_attribute_id/edit";

  editWith(available_attribute_id: string): string {
    return this.edit.replace(":available_attribute_id", available_attribute_id);
  }
}

class VariantsRoutes {
  list = "/variants";
  create = "/variants/create";
  edit = "/variants/:variant_id/edit";

  editWith(variant_id: string): string {
    return this.edit.replace(":variant_id", variant_id);
  }
}

class OrganizationsRoutes {
  list = `/organizations`;
  create = `/organizations/create`;
  details = `/organizations/:organization_id`;
  edit = "/organizations/:organization_id/edit";

  editWith(organization_id: string): string {
    return this.edit.replace(":organization_id", organization_id);
  }

  detailsWith(organization_id: string): string {
    return `/organizations/${organization_id}`;
  }
}

class OffersRoutes {
  list = `/offers`;
  create = `/offers/create`;
  details = `/offers/:offer_id`;
  edit = "/offers/:offer_id/edit";

  detailsWith(offer_id: string): string {
    return this.details.replace(":offer_id", offer_id);
  }

  editWith(offer_id: string): string {
    return this.edit.replace(":offer_id", offer_id);
  }
}

class VehiclesRoutes {
  list = `/vehicles`;
  modelsMakes = "/models-makes";
  createMake = "/models-makes/make/create";
  createModel = "/models-makes/make/:make_id/model/create";

  createModelWith(make_id: string): string {
    return this.createModel.replace(":make_id", make_id);
  }
}

class DashboardRoutes {
  statistics = `/dashboard/statistics`;
  ordersReport = `/dashboard/orders-report`;
}

class GeneralRoutes {
  invitations = "/invitations";
  contactUs = "/contact_us";
  devices = `/devices`;
  geozones = `/geozones`;
}
export class AppRoute {
  // admin routes - start
  static orders = new OrderRoutes();

  static carts = new CartsRoutes();

  static users = new UsersRoutes();

  static brands = new BrandsRoutes();

  static products = new ProductsRoutes();

  static variants = new VariantsRoutes();

  static offers = new OffersRoutes();

  static vehicles = new VehiclesRoutes();

  static dashboard = new DashboardRoutes();

  static categories = new CategoriesRoutes();

  static currencies = new CurrenciesRoutes();

  static attributes = new AttributesRoutes();

  static availableAttributes = new AvailableAttributesRoutes();

  static organizations = new OrganizationsRoutes();

  static general = new GeneralRoutes();
}
