import { DynamicValuesType } from "../../core/types/dynamic.type";
import { HttpMethods } from "../enums/http-methods.enum";
import { makeRequest, baseURL } from "./http.service";

export const getUser = async () => {
  return await makeRequest({
    url: `${baseURL}/users/me`,
    method: HttpMethods.GET,
  });
};

export const getUsers = async (params?: DynamicValuesType) => {
  return await makeRequest({
    url: `${baseURL}/users`,
    method: HttpMethods.GET,
    params,
  });
};
