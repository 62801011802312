import { ErrorType } from "../types/error.types";
import { toast } from "react-toastify";
import { AppRoute } from "../routes/app.route.util";

export const userAuthCheck = (error: any) => {
  toast.error(
    (error as ErrorType).response.data.message || "something went wrong!"
  );
};

export const logout = () => {
  sessionStorage.removeItem("token");
  window.location.href = AppRoute.users.login;
};
