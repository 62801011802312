import { UserType } from "../types/user.type";
import create from "zustand";
import { devtools } from "zustand/middleware";

export type UserStoreModel = {
  user: UserType;
};

const store = (set: (arg: () => UserStoreModel) => void) => ({
  user: {} as UserType,
  setUserData: (user: UserType) => {
    set(() => ({
      user,
    }));
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUserStore: any = create(devtools(store));

export default useUserStore;
