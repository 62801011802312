import axios from "axios";
import i18n from "i18next";
// import { v4 as uuidv4 } from "uuid";

// Set config defaults when creating the instance
const axiosObject = axios.create();

export const userUuid = "AAABBB";

axiosObject.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const token = sessionStorage.getItem("token");
    const headers = { "X-App-Info": "ORO 100.0.0 100" };
    config = {
      ...config,
      headers,
    };
    if (sessionStorage.getItem("token")) {
      config = {
        ...config,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "X-Device-UUID": userUuid,
          "Accept-Language": i18n.language,
          ...config.headers,
        },
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    console.error("error: interceptors", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosObject.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosObject;
