import salesIcon from "../../assets/icons/sales.svg";
import ordersIcon from "../../assets/icons/orders.svg";
import cartIcon from "../../assets/icons/cart.svg";
import organizationsIcon from "../../assets/icons/organizations.svg";
import geozonesIcon from "../../assets/icons/geozones.svg";
import invitationsIcon from "../../assets/icons/invitations.svg";
import contactUsIcon from "../../assets/icons/contact_us.svg";
import partnersIcon from "../../assets/icons/partners.svg";
import offersIcon from "../../assets/icons/offers.svg";
import marketingIcon from "../../assets/icons/marketing.svg";
import modelsIcon from "../../assets/icons/models.svg";
import operationsIcon from "../../assets/icons/operations.svg";
import vehiclesIcon from "../../assets/icons/vehicles.svg";
import marketplaceIcon from "../../assets/icons/marketplace.svg";
import brandsIcon from "../../assets/icons/brands.svg";
import categoriesIcon from "../../assets/icons/categories.svg";
import productsIcon from "../../assets/icons/products.svg";
import variantsIcon from "../../assets/icons/variants.svg";
import currenciesIcon from "../../assets/icons/currencies.svg";
import authIcon from "../../assets/icons/auth.svg";
import devicesIcon from "../../assets/icons/devices.svg";
import usersIcon from "../../assets/icons/users.svg";

import { AppRoute } from "../../common/routes/app.route.util";

export const getGroupedAdminSidebarMenu = (path: string) => {
  return [
    {
      group_name: "Dashboard",
      group_icon: salesIcon,
      isActive: path.includes("/dashboard"),
      links: [
        {
          icon: cartIcon,
          title: "Current Numbers",
          isActive: path.includes(AppRoute.dashboard.statistics),
          url: AppRoute.dashboard.statistics,
        },
        {
          icon: cartIcon,
          title: "Orders Reports",
          isActive: path.includes(AppRoute.dashboard.ordersReport),
          url: AppRoute.dashboard.ordersReport,
        },
      ],
    },
    {
      group_name: "Sales",
      group_icon: salesIcon,
      isActive: path.includes("carts") || path.includes("orders"),
      links: [
        {
          icon: cartIcon,
          title: "Carts",
          isActive: path.includes("carts"),
          url: AppRoute.carts.list,
        },
        {
          icon: ordersIcon,
          title: "Orders",
          isActive: path.includes("orders"),
          url: AppRoute.orders.list,
        },
      ],
    },
    {
      group_name: "Partners",
      group_icon: partnersIcon,
      isActive:
        path.includes("organizations") ||
        path.includes("geozones") ||
        path.includes("invitations") ||
        path.includes("contact_us"),
      links: [
        {
          icon: organizationsIcon,
          title: "Organizations",
          isActive: path.includes("organizations"),
          url: AppRoute.organizations.list,
        },
        {
          icon: geozonesIcon,
          title: "Geozones",
          isActive: path.includes("geozones"),
          url: AppRoute.general.geozones,
        },
        {
          icon: invitationsIcon,
          title: "Invitations",
          isActive: path.includes("invitations"),
          url: AppRoute.general.invitations,
        },
        {
          icon: contactUsIcon,
          title: "Contact Us Forms",
          isActive: path.includes("contact_us"),
          url: AppRoute.general.contactUs,
        },
      ],
    },
    {
      group_name: "Marketing",
      group_icon: marketingIcon,
      isActive: path.includes("offers"),
      links: [
        {
          icon: offersIcon,
          title: "Offers",
          isActive: path.includes("offers"),
          url: AppRoute.offers.list,
        },
      ],
    },
    {
      group_name: "Operations",
      group_icon: operationsIcon,
      isActive: path.includes("vehicles") || path.includes("models-makes"),
      links: [
        {
          icon: vehiclesIcon,
          title: "Vehicles",
          isActive: path.includes("vehicles"),
          url: AppRoute.vehicles.list,
        },
        {
          icon: modelsIcon,
          title: "Makes Models",
          isActive: path.includes("models-makes"),
          url: AppRoute.vehicles.modelsMakes,
        },
      ],
    },
    {
      group_name: "Marketplace",
      group_icon: marketplaceIcon,
      isActive:
        path.includes("brands") ||
        path.includes("categories") ||
        path.includes("products") ||
        path.includes("variants") ||
        path.includes("attributes") ||
        path.includes("available-attributes") ||
        path.includes("currencies"),
      links: [
        {
          icon: brandsIcon,
          title: "Brands",
          isActive: path.includes("brands"),
          url: AppRoute.brands.list,
        },
        {
          icon: categoriesIcon,
          title: "Categories",
          isActive: path.includes("categories"),
          url: AppRoute.categories.list,
        },
        {
          icon: productsIcon,
          title: "Products",
          isActive: path.includes("products"),
          url: AppRoute.products.list,
        },
        {
          icon: variantsIcon,
          title: "Variants",
          isActive: path.includes("variants"),
          url: AppRoute.variants.list,
        },
        {
          icon: currenciesIcon,
          title: "Currencies",
          isActive: path.includes("currencies"),
          url: AppRoute.currencies.list,
        },
        {
          icon: ordersIcon,
          title: "Attributes",
          isActive: path.includes("/attributes"),
          url: AppRoute.attributes.list,
        },
        {
          icon: ordersIcon,
          title: "Available Attributes",
          isActive: path.includes("available-attributes"),
          url: AppRoute.availableAttributes.list,
        },
      ],
    },
    {
      group_name: "Auth",
      group_icon: authIcon,
      isActive: path.includes("users") || path.includes("devices"),
      links: [
        {
          icon: usersIcon,
          title: "Users",
          isActive: path.includes("users"),
          url: AppRoute.users.list,
        },
        {
          icon: devicesIcon,
          title: "Devices",
          isActive: path.includes("devices"),
          url: AppRoute.general.devices,
        },
      ],
    },
  ];
};
